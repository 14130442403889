import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import * as React from 'react'

import * as styles from '@/components/News/Title/Title.module.scss'
import '@/components/News/Title/breadcrumb.scss'

type Props = {
  crumbs: {
    pathname: string
    crumbLabel: string
  }[]
  crumbLabel: string
}

const NewsTitle = ({ crumbs, crumbLabel }: Props) => {
  return (
    <section className={styles['newsTitle']}>
      <h1 className={styles['title']}>おしらせ。</h1>
      <div className='breadcrumb-newsTitle'>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator='>'
          crumbLabel={crumbLabel}
        />
      </div>
    </section>
  )
}

export default NewsTitle
