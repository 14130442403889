import { useStaticQuery, graphql } from 'gatsby'

export const useNews = () => {
  const { allMicrocmsNews } = useStaticQuery<Queries.NewsQuery>(
    graphql`
      query News {
        allMicrocmsNews(sort: { fields: publishedAt, order: DESC }) {
          nodes {
            newsId
            title
            body
            tags {
              id
              name
            }
            publishedAt
            imgixImage {
              gatsbyImageData
            }
          }
        }
      }
    `
  )

  return allMicrocmsNews.nodes
}
