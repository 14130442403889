// extracted by mini-css-extract-plugin
export var container = "Notice-module--container--fX7iR";
export var fadeDown = "Notice-module--fadeDown--+OtNt";
export var fadeUp = "Notice-module--fadeUp--nzrRY";
export var flowing = "Notice-module--flowing--6Vo7M";
export var image = "Notice-module--image--ItNbm";
export var lineIn = "Notice-module--lineIn--s11-E";
export var link = "Notice-module--link--b91aN";
export var notice = "Notice-module--notice--145zd";
export var tag = "Notice-module--tag--aMnpS";
export var tags = "Notice-module--tags--tYacx";
export var time = "Notice-module--time--rGTHk";
export var title = "Notice-module--title--jDLl0";