import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as React from 'react'

import * as styles from '@/components/News/Main/Main.module.scss'
import MediaBreak from '@/components/Parts/MediaBreak'
import { useNews } from '@/hooks/useNews'

type Props = {
  newsId: string
}

const NewsMain = ({ newsId }: Props) => {
  const newsList = useNews()
  const news = newsList.find((news) => news.newsId === newsId)
  const newsIndex = newsList.findIndex((news) => news.newsId === newsId)

  const image = news ? getImage(news.imgixImage.gatsbyImageData) : undefined

  return news ? (
    <section className={styles['newsMain']}>
      <div className={styles['article']}>
        <time className={styles['time']}>
          {new Date(news.publishedAt)
            .toLocaleDateString('ja-JP')
            .replaceAll('/', '.')}
        </time>
        <h2 className={styles['title']}>{news.title}</h2>
        {news.tags.length ? (
          <div className={styles['tags']}>
            {news.tags.map((tag) => (
              <div className={styles['tag']} key={tag.id}>
                {tag.name}
              </div>
            ))}
          </div>
        ) : undefined}
        {image ? (
          <GatsbyImage
            className={styles['image']}
            image={image}
            alt={news.title}
          />
        ) : undefined}
        <div
          className={styles['body']}
          dangerouslySetInnerHTML={{
            __html: `${news.body}`,
          }}
        />
        <div className={styles['divider']} />
        <nav className={styles['nav']}>
          <Link
            className={`${styles['prev']} ${
              newsIndex === newsList.length - 1 ? styles['hidden'] : undefined
            }`}
            to={
              newsIndex === newsList.length - 1
                ? '/news'
                : `/news/${newsList[newsIndex + 1]?.newsId}`
            }
          >
            &lt; Prev
          </Link>
          <Link className={styles['home']} to='/news'>
            おしらせ
            <MediaBreak media='mobile' />
            一覧に戻る
          </Link>
          <Link
            className={`${styles['next']} ${
              newsIndex === 0 ? styles['hidden'] : undefined
            }`}
            to={
              newsIndex === 0
                ? '/news'
                : `/news/${newsList[newsIndex - 1]?.newsId}`
            }
          >
            Next &gt;
          </Link>
        </nav>
      </div>
    </section>
  ) : (
    <></>
  )
}

export default NewsMain
