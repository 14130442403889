import * as React from 'react'

import type { HeadFC, PageProps } from 'gatsby'

import SEO from '@/components/Base/SEO'
import Layout from '@/components/Layout'
import NewsLatest from '@/components/News/Latest'
import NewsMain from '@/components/News/Main'
import NewsTitle from '@/components/News/Title'
import { useNews } from '@/hooks/useNews'

type PageContext = {
  breadcrumb: {
    crumbs: {
      pathname: string
      crumbLabel: string
    }[]
  }
}

const NewsPage = ({
  params,
  pageContext: {
    breadcrumb: { crumbs },
  },
}: PageProps<Queries.SitePage, PageContext>) => {
  const news = useNews().find((news) => news.newsId === params['newsId'])

  return (
    <Layout logo={true} dark={true}>
      <main>
        <NewsTitle
          crumbs={crumbs}
          crumbLabel={news ? news.title : '記事が見つかりませんでした'}
        />
        <NewsMain newsId={params['newsId'] ?? ''} />
        <NewsLatest newsId={params['newsId'] ?? ''} />
      </main>
    </Layout>
  )
}

export default NewsPage

export const Head: HeadFC = ({ params, location }) => {
  const news = useNews().find((news) => news.newsId === params['newsId'])

  return (
    <SEO
      title={news ? news.title : '記事が見つかりませんでした'}
      pathname={location.pathname}
      page='news'
    />
  )
}
