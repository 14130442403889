// extracted by mini-css-extract-plugin
export var article = "Main-module--article--zBGFQ";
export var body = "Main-module--body--fAZQ2";
export var divider = "Main-module--divider--NulCh";
export var fadeDown = "Main-module--fadeDown--8u3lm";
export var fadeUp = "Main-module--fadeUp--jIbQU";
export var flowing = "Main-module--flowing--C5VGi";
export var hidden = "Main-module--hidden--j45Kv";
export var home = "Main-module--home--tK2ZQ";
export var image = "Main-module--image--q-jkv";
export var lineIn = "Main-module--lineIn--ksEjD";
export var nav = "Main-module--nav--P40pU";
export var newsMain = "Main-module--newsMain--ungik";
export var next = "Main-module--next--76d-D";
export var prev = "Main-module--prev--hXMsU";
export var tag = "Main-module--tag--s-qX+";
export var tags = "Main-module--tags--PAvzp";
export var time = "Main-module--time--l4u60";
export var title = "Main-module--title--jWjib";