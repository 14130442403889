import { getImage } from 'gatsby-plugin-image'
import * as React from 'react'

import * as styles from '@/components/News/Latest/Latest.module.scss'
import Notice from '@/components/Parts/Notice'
import { useNews } from '@/hooks/useNews'

type Props = {
  newsId: string
}

const NewsLatest = ({ newsId }: Props) => {
  const newsList = useNews()
  const filteredNewsList = newsList.filter((news) => news.newsId !== newsId)

  return filteredNewsList.length ? (
    <section className={styles['latestNews']}>
      {filteredNewsList.slice(0, 3).map((news) => {
        const image = getImage(news.imgixImage.gatsbyImageData)
        return image ? (
          <Notice
            key={news.newsId}
            id={news.newsId}
            title={news.title}
            tags={news.tags}
            date={news.publishedAt}
            image={image}
          />
        ) : undefined
      })}
    </section>
  ) : (
    <></>
  )
}

export default NewsLatest
