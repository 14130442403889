import { Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import * as React from 'react'

import * as styles from '@/components/Parts/Notice/Notice.module.scss'

type Props = {
  id: string
  title: string
  tags: readonly { id: string; name: string }[]
  date: string
  image: IGatsbyImageData
}

const Notice = ({ id, title, tags, date, image }: Props) => {
  return (
    <article className={styles['notice']}>
      <GatsbyImage className={styles['image']} image={image} alt={title} />
      <div className={styles['container']}>
        <time className={styles['time']}>
          {new Date(date).toLocaleDateString('ja-JP').replaceAll('/', ' / ')}
        </time>
        <h2 className={styles['title']}>{title}</h2>
        {tags.length ? (
          <div className={styles['tags']}>
            {tags.map((tag) => (
              <div className={styles['tag']} key={tag.id}>
                {tag.name}
              </div>
            ))}
          </div>
        ) : undefined}
      </div>
      <Link className={styles['link']} to={`/news/${id}`} />
    </article>
  )
}

export default Notice
